
import $ from 'jquery'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

mapboxgl.accessToken = 'pk.eyJ1IjoiZmxpcHBkZXZzIiwiYSI6ImNrc2hxc2x0ZDF3eGQyb29hZDNndWhuZXcifQ.NLQN5AjdofpVMB8SBRgDgA';

if( $( '.buildings-map' ).length ) {

  const map3 = new mapboxgl.Map({
    container: 'map3',
    style: 'mapbox://styles/flippdevs/cl7anhg6k000i14jz2u9mvgxt',
    center: [-106.552288, 52.129014 ],
    zoom: 13.5
  });

  map3.on('idle',function(){
    map3.resize()
    })
  const mapLegend = document.querySelectorAll( '.map-legend-title' );

  mapLegend.forEach( ( legend, index ) => {

    let amenityLong = legend.dataset.long;
    let amenityLat = legend.dataset.lat;
    let amenityColor = legend.dataset.color;
    let amenityInfo = $( '.popup-info' )[index].innerHTML;
    let el = document.createElement('div');
    el.className = 'marker-amenity fill-' + amenityColor ;

    const popup = new mapboxgl.Popup( { offset: 10 } ) // add popups

    .setHTML(
      `${amenityInfo}`
    )

    const marker = new mapboxgl.Marker(el).setLngLat([ amenityLong, amenityLat ]).setPopup( popup ).addTo( map3 );

    el.addEventListener( 'click', function() {
      
      map3.flyTo({
        center: [amenityLong,amenityLat], 
        offset: [0, 100],
        zoom: 15, 
        speed: 0.4,
      });
    });
    
    legend.addEventListener( 'click', function() {
      
      $( '.mapboxgl-popup' ).remove();

      map3.flyTo({
        center: [amenityLong,amenityLat], 
        offset: [0, 100],
        zoom: 15, 
        speed: 0.4,
      });

      popup.addTo(map3);
    });

  } )
  
}

if( $( '.amenities-map' ).length ) {

  const map2 = new mapboxgl.Map({
    container: 'map2',
    style: 'mapbox://styles/flippdevs/cl7anhg6k000i14jz2u9mvgxt',
    center: [-106.552288, 52.129014 ],
    zoom: 13.5
  });

  map2.on('idle',function(){
    map2.resize()
    })
  const mapLegend = document.querySelectorAll( '.map-legend-title' );

  mapLegend.forEach( ( legend, index ) => {

    let amenityLong = legend.dataset.long;
    let amenityLat = legend.dataset.lat;
    let amenityColor = legend.dataset.color;
    let amenityType = legend.dataset.type;
    let amenityName = legend.innerHTML;
    let amenityInfo = $( '.popup-info' )[index].innerHTML;
    let el = document.createElement('div');
    el.className = 'marker-amenity fill-' + amenityColor + ' all ' + amenityType;

    const popup = new mapboxgl.Popup( { offset: 10 } ) // add popups
    .setHTML(
      `<h2 class="header-3 mb-8 text-${ amenityColor }">${ amenityName }</h2>
      ${amenityInfo}`
    )

    const marker = new mapboxgl.Marker(el).setLngLat([amenityLong, amenityLat]).setPopup( popup ).addTo(map2);

    el.addEventListener( 'click', function() {
      
      map2.flyTo({
        center: [amenityLong,amenityLat], 
        zoom: 15, 
        speed: 0.4,
      });
    });
    
    legend.addEventListener( 'click', function() {
      
      $( '.mapboxgl-popup' ).remove();

      map2.flyTo({
        center: [amenityLong,amenityLat], 
        zoom: 15, 
        speed: 0.4,
      });

      popup.addTo(map2);
    });
  } )
  $( '.legend-item .title' ).on( 'click', function(){

    map2.flyTo({
      center: [-106.552288, 52.129014 ],
      zoom: 13.5
    })

    if( $(this).parent('.accordion').hasClass('open')) {
      var filter = "all";
    } else {
      var filter = $(this).data('type');
    }
    
    $('.marker-amenity').each( function() {

      $(this).addClass( 'hide' );

      $( '.mapboxgl-popup' ).remove();

      if( $(this).hasClass(filter) ) {

        $(this).removeClass( 'hide' );
      }
    });
  })

  $( '.map-list-icon' ).on( 'click', function() {
    $( '.map-legend-container' ).toggleClass( 'active' );
    $( '.map-list-icon' ).toggleClass( 'active' );
  })
}

//this is the districts map
if( $( '#map' ).length ) {

  const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/flippdevs/cl7anhg6k000i14jz2u9mvgxt',
    center: [-106.552288, 52.129014 ],
    zoom: 13.5,
    bearing: 27,
    pitch: 45
  });

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ -106.558, 52.129 ]
        },
        properties: {
          title: '01',
          description: 'Market side'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ -106.553, 52.126 ]
        },
        properties: {
          title: '02',
          description: 'Park side'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ -106.554, 52.121 ]
        },
        properties: {
          title: '03',
          description: 'Waters Edge'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ -106.544, 52.127 ]
        },
        properties: {
          title: '04',
          description: 'Village West'
        }
      }
    ]
  }

  const sections = {
    'marketside': {
      bearing: 27,
      center: [ -106.558, 52.129 ],
      zoom: 15.5,
      speed: 0.4,
      pitch: 20
    },
    'parkside': {
      // duration: 3000,
      center: [ -106.553, 52.126 ],
      bearing: 10,
      zoom: 15,
      speed: 0.4,
      pitch: 10
    },
    'watersedge': {
      bearing: -70,
      center: [ -106.554, 52.121 ],
      zoom: 16,
      speed: 0.4,
      pitch: 60
    },
    'villagewest': {
      bearing: -30,
      center: [ -106.544, 52.127 ],
      zoom: 15.3,
      speed: 0.4
    },
    'villageeast': {
      bearing: -90,
      center: [ -106.537, 52.129 ],
      zoom: 15.3,
      pitch: 40,
      speed: 0.4
    }
  };
  
  gsap.registerPlugin( ScrollTrigger );

  ScrollTrigger.create({
    trigger: '.map-container',
    pin: '#map',
    start: 'top top',
    end: 'bottom bottom',
    // markers: true,
  });  

  let feature = document.querySelectorAll( '.feature-section' );
  
  feature.forEach((elem, i) => {
    let sectionName = elem.id;
    ScrollTrigger.create({
      trigger: elem,
      start:"top center", 
      end: 'bottom center',
      toggleClass: 'active',
      onEnter: () => map.flyTo(sections[sectionName]),
      onEnterBack: () => map.flyTo(sections[sectionName]),
    });
  });
  
  window.onload = () => {
    setTimeout(() => {
      map.flyTo(sections['marketside']);
      document.getElementById('marketside').classList.add('active');
      ScrollTrigger.refresh();
    }, '2000')
  };  

  function scrollTo(id) {
    var offTop = $('#' + id ).offset().top;   
    $('html,body').scrollTop(offTop);
  }
  
  map.on('load', () => {

      map.setLayoutProperty('marketside','visibility','visible');
      map.setLayoutProperty('parkside','visibility','visible');
      map.setLayoutProperty('watersedge','visibility','visible');
      map.setLayoutProperty('villagewest','visibility','visible');

    const layers = [ 'marketside', 'parkside', 'watersedge', 'villagewest', 'villageeast' ];

    layers.forEach( ( layer ) => {
    //   map.setPaintProperty(
    //     layer,
    //     'fill-opacity',
    //     0
    // );
      map.on('click', layer, (e) => {
      //   map.setPaintProperty(
      //     layer,
      //     'fill-opacity',
      //     1
      // );

        map.flyTo(sections[layer]);
        scrollTo(layer);
      });   
    })
    
    map.on('mouseenter', layers, () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    // Change it back to a pointer when it leaves.
    map.on('mouseleave', layers, () => {
      map.getCanvas().style.cursor = '';
    });

    // add markers to map
    geojson.features.forEach(function(marker, i) {

      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';
      el.innerHTML = '<span>0' + (i + 1) + '</span>'
      
      new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
    });
  });
}
